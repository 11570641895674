import React from 'react';
import WebsiteImage from 'components/WebsiteImage';
import { WithUserContext } from 'utils/UserContext';
import LoggedInUserDownload from 'components/Page/Toolbox/LoggedInUserDownload';
import MarketoForm from '../MarketoForm';

const FixedDynamicMarketoForm = ({
  className,
  dataPosition,
  titleImage,
  formName,
  formType,
  shadow,
  inlineMarketingForm,
  title,
  showPrivacy,
  affixMobile,
  userInfo,
  ...otherProps
}) => {
  return (
    <div className={className || ''} id="fixed-embed-marketo--form">
      {titleImage && (
        <WebsiteImage
          className="fixed-embed-marketo--title-image"
          image={titleImage}
          loading="eager"
        />
      )}
      {userInfo ? (
        <LoggedInUserDownload
          inlineMarketingForm={inlineMarketingForm}
          title={formName}
          buttonTitle={title || 'Download Now'}
          hasTitleImage={!!titleImage}
        />
      ) : (
        <MarketoForm
          dataPosition={dataPosition}
          formName={formName}
          formType={formType}
          shadow={shadow}
          inlineMarketingForm={inlineMarketingForm}
          title={title}
          showPrivacy={showPrivacy}
          affixMobile={affixMobile}
          {...otherProps}
        />
      )}
    </div>
  );
};

export default WithUserContext(FixedDynamicMarketoForm);
