import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import ReactResizeDetector from 'react-resize-detector';
import ToolboxLayout from 'layouts/toolboxLayout';
import StyledRichText from 'components/RichText/StyledRichText';
import GatedHeroSection from 'components/Page/GatedContentTemplate/GatedHeroSection';
import toolboxLogos from 'components/Page/Toolbox/data/toolboxLogos';
import FixedDynamicMarketoForm from 'components/Form/FixedDynamicMarketoForm';
import BookmarkContext from 'utils/BookmarkContext';

const GatedContentTemplate = (props) => {
  const {
    assetThumbnail,
    mainContent,
    inlineMarketingForm,
    seo: seoContent,
    background,
    headingKicker,
    heading,
    subhead,
  } = props.data.contentfulGatedContentPage;
  const { articlesLogo } = toolboxLogos();
  const breadcrumbs = {
    data: [
      {
        title: 'Toolbox',
        icon: null,
        url: '/toolbox',
      },
      {
        title: 'Guides',
        icon: articlesLogo,
        url: '/guides',
      },
    ],
    absolute: true,
    transparent: true,
    theme: 'light',
  };

  const handleSideHeight = (width, height) => {
    const pageContainer = document.querySelector('.gated-page--container');
    pageContainer.style.minHeight = `${height + 130}px`;
  };

  const bookmarkData = {
    type: 'guide',
    url: props.path,
    title: heading,
    subTitle: headingKicker,
    image: props.data.contentfulGatedContentPage?.assetThumbnail,
  };

  const gatedForm = (
    <div className="gated-page--side-form-container">
      <div className="gated-page--side-form-wrapper">
        <ReactResizeDetector handleHeight onResize={handleSideHeight}>
          <FixedDynamicMarketoForm
            className="gated-page--side-form"
            width="100%"
            titleImage={assetThumbnail}
            shadow
            formType="Gated Form"
            showPrivacy={false}
            formId="side-gated-form"
            inlineMarketingForm={{ ...inlineMarketingForm, ignoreSubmitted: true }}
            {...props}
          />
        </ReactResizeDetector>
      </div>
    </div>
  );

  return (
    <BookmarkContext.Provider value={bookmarkData}>
      <ToolboxLayout
        breadcrumbs={breadcrumbs}
        contentfulSeo={seoContent}
        layout="toolbox"
        {...props}
      >
        <GatedPageLayout>
          <div className="gated-page--container">
            <GatedHeroSection
              backgroundColor="var(--dark)"
              subTitle={headingKicker}
              title={heading}
              description={subhead}
              background={background}
            />
            {inlineMarketingForm && gatedForm}
            <Contents className="section-webinar-content--container">
              <StyledRichText content={mainContent} />
            </Contents>
          </div>
        </GatedPageLayout>
      </ToolboxLayout>
    </BookmarkContext.Provider>
  );
};

export default GatedContentTemplate;

export const GatedContentTemplatePageQuery = graphql`
  query GatedContentTemplatePageQuery($slug: String!) {
    contentfulGatedContentPage(seo: { pageUrl: { eq: $slug } }) {
      ...GatedContent
    }
  }
`;

const GatedPageLayout = styled.div`
  .gated-page--container {
    position: relative;
    padding-bottom: 40px;
    .section-webinar-hero--container {
      max-width: 1252px;
    }
    .gated-page--side-form-container {
      position: absolute;
      top: 80px;
      right: 0;
      width: 100%;
      .gated-page--side-form-wrapper {
        max-width: 1252px;
        margin: 0 auto;
        position: relative;
        .gated-page--side-form {
          position: absolute;
          right: 28px;
          max-width: 372px;

          .fixed-embed-marketo--title-image {
            width: 100%;
            padding: 30px;
          }
        }
      }
    }
  }
  @media (max-width: 1450px) {
    .gated-page--container {
      margin-top: 0px;
    }
  }
  @media (max-width: 991px) {
    .gated-page--container {
      .section-webinar-hero--container {
        padding-right: 340px;
      }
      .gated-page--side-form-container {
        top: 100px;
        .gated-page--side-form-wrapper {
          .gated-page--side-form {
            max-width: 300px;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    .gated-page--container {
      .section-webinar-hero--container {
        padding-right: 28px;
      }
      .gated-page--side-form-container {
        position: relative;
        top: 20px;
        .gated-page--side-form-wrapper {
          padding: 40px 28px 0;
          .gated-page--side-form {
            max-width: 100%;
            position: relative;
            right: 0;
          }
        }
      }
    }
  }
  @media (max-width: 695px) {
    .gated-page--container {
      .section-webinar-hero--container {
        padding-right: 20px;
      }
      .gated-page--side-form-container {
        .gated-page--side-form-wrapper {
          padding: 40px 20px 0;
          .gated-page--side-form {
            padding: 0;
          }
        }
      }
    }
  }
`;

const Contents = styled.div`
  padding: 50px 440px 36px 28px;
  margin: 0 auto;
  text-align: left;
  max-width: 1252px;

  p {
    color: rgba(0, 0, 0, 0.65);
    font-size: var(--font-size-18);
    font-weight: var(--font-weight-400);
  }
  a {
    u {
      text-decoration: none;
    }
  }
  ul {
    padding-left: 40px;
    li {
      font-size: var(--font-size-18);
      p {
        font-size: var(--font-size-18);
        line-height: 1.5;
        margin-top: 0px;
        margin-bottom: 0px;
      }
    }
  }

  img {
    width: 100% !important;
  }

  &.rich-text--overview {
    margin-top: 20px;

    p {
      font-weight: var(--font-weight-500);
      font-size: var(--font-size-20);
      line-height: var(--line-height-150);
      color: var(--darkest);

      @media (max-width: 767px) {
        font-size: var(--font-size-18);
      }
    }
  }

  @media (max-width: 991px) {
    padding-right: 370px;
  }

  @media (max-width: 767px) {
    padding-right: 28px;
  }

  @media (max-width: 695px) {
    padding-right: 20px;
  }
`;
